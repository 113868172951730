import React from 'react'

import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'

import { useThemeMode } from '../../hooks/useThemeMode'
import { RoundedNavButton } from '../../../components/common/NavButton/NavButton'

const ThemeModeToggler = ({ onlyIcon = false }) => {
  const { toggleMode, mode } = useThemeMode()
  return (
    <RoundedNavButton
      onClick={toggleMode}
      displayName={onlyIcon ? '' : `${mode} Mode`}
      icon={mode === 'dark' ? <DarkModeIcon color='primary'/> : <LightModeIcon color='primary'/>}
    />
  )
}

export default ThemeModeToggler
