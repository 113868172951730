import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useAuth from './useAuth'
import useRefreshToken from './useRefreshToken'
import { parseUserInfo } from '../../utils'

// Persists the user info by decoding the jwt token info on page load and update the store

const useAuthPersist = () => {
  const [isLoading, setLoading] = useState(true)
  const { auth, login } = useAuth()
  const refresh = useRefreshToken()
  const dispatch = useDispatch()

  useEffect(() => {
    let noRefreshToken = false
    const verifyRefreshToken = async () => {
      try {
        if (!noRefreshToken) {
          await refresh()
        }
      } catch (error) {
        if (error.response.status !== 200) {
          noRefreshToken = true
        }
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    !auth?.user ? verifyRefreshToken() : setLoading(false)
  }, [auth?.user, refresh])

  useEffect(() => {
    const token = auth?.user?.token
    if (token) {
      const user = parseUserInfo(token)
      dispatch(login(user))
    }
  }, [isLoading, dispatch, login, auth?.user?.token])

  return isLoading
}

export default useAuthPersist
