import axiosPrivate from '../api/axios'
import { API_URL, METHODS } from '../api/constants'

const useDeleteToken = () => {
  const deleteToken = async () => {
    try {
      return await axiosPrivate(API_URL.DELETE, {
        withCredentials: true,
        method: METHODS.DELETE
      })
    } catch (error) {
      console.error(error)
    }
  }
  return deleteToken
}

export default useDeleteToken
