import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { VALIDATION_RULES } from '../../../constants/constants'
import { useTheme } from '@emotion/react'
import { validate } from '../../../utils'

const InputField = React.memo((props) => {
  const {
    name,
    label,
    value = '',
    onChange,
    onError,
    required,
    validator,
    error,
    rowId,
    InputProps,
    inputProps,
    readOnly = false,
    title,
    hint,
  } = props
  const [touched, setTouched] = useState(false)

  const theme = useTheme()
  const isDarkMode = theme?.palette?.mode === 'dark'

  const handleValidations = () => {
    const validators = [
      ...(validator
        ? Array.isArray(validator)
          ? [...validator]
          : [validator]
        : []),
      ...(required ? [VALIDATION_RULES.REQUIRED] : []),
    ]
    if (validators.length) {
      const valid = validate(value, validators)
      onError(name, valid, rowId)
    }
  }
  const handleChange = (e) => {
    onChange(e, rowId)
  }

  useEffect(() => {
    handleValidations()
    // eslint-disable-next-line
  }, [value])

  const updateInputProps = { ...inputProps, readOnly }
  return (
    <TextField
      name={name}
      label={label}
      value={value}
      required={required}
      onChange={readOnly ? undefined : handleChange}
      onBlur={readOnly ? undefined : () => setTouched(true)}
      title={readOnly ? title : ''}
      sx={{
        '& input': {
          backgroundColor: readOnly
            ? `${isDarkMode ? theme.palette.grey[900] : theme.palette.grey[50]}`
            : 'initial',
          cursor: readOnly ? 'not-allowed' : 'initial',
        },
        '& .MuiFormHelperText-root': {
          position: 'absolute',
          bottom: '-1.6em',
          marginLeft: 0,
        },
      }}
      error={touched && error && error !== true}
      helperText={touched && error !== true ? error : hint}
      InputProps={{
        readOnly: readOnly,
        ...InputProps,
      }}
      inputProps={updateInputProps}
    />
  )
})

export default InputField
