import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { GridRowModes } from '@mui/x-data-grid-pro'

import { LinearProgress, Typography } from '@mui/material'
import Snackbar from '../common/Snackbar'
import { guid } from '../../utils'
import { useAxiosPrivate } from '../../hooks'
import DefaultToolbarContainer from '../common/kc-table/components/toolbar-container/DefaultToolbarContainer'
import { KcTable } from '../common/kc-table'
import { CONST_VARIANT } from '../common/kc-table/constants'
import {
  CustomNoRowsOverlay,
  CustomPagination,
} from '../common/kc-table/components'
import { CONTENT } from '../../constants/constants'

export const AddConfigButton = (props) => (
  <Button
    color="primary"
    startIcon={<AddIcon />}
    onClick={props?.onClick}
    variant="contained"
    sx={{
      zIndex: '1',
    }}
  >
    Add Config
  </Button>
)

export default function GlobalConfigList({
  data,
  loading,
  refetch,
  getColumns,
  defaultPageSize = 10,
  onSave,
  onDelete,
  maxHeight,
  initialState,
  children,
}) {
  const [rows, setRows] = useState([])
  const [rowModesModel, setRowModesModel] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [pageNo, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [snackbar, setSnackbar] = React.useState(null)

  const axiosPrivate = useAxiosPrivate()

  const queryOptions = React.useMemo(
    () => ({
      pageNo,
      pageSize,
    }),
    [pageNo, pageSize]
  )

  useEffect(() => {
    refetch(queryOptions, axiosPrivate)
    // eslint-disable-next-line
  }, [queryOptions])

  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = React.useState(
    data?.totalCount || 0
  )

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      data?.totalCount !== undefined ? data?.totalCount : prevRowCountState
    )
  }, [data?.totalCount, setRowCountState])

  useEffect(() => {
    setRows(data?.listOfConfigs || [])
  }, [data?.listOfConfigs])

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  const handleCloseSnackbar = () => setSnackbar(null)

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' })
  }, [])

  const handleClick = () => {
    const id = guid()
    setRows((oldRows) => [
      ...oldRows,
      { id, key: '', value: '', comment: '', isNew: true },
    ])
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'key' },
    }))
  }

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id) => async () => {
    try {
      setLoading(true)
      const response = await onDelete(id)
      if (response.status === 200) {
        setSnackbar({
          children: response?.message,
          severity: 'success',
        })
        refetch(queryOptions)
      }
    } catch (error) {
      setSnackbar({ children: error?.message, severity: 'error' })
    } finally {
      setLoading(false)
    }
    setRows(rows.filter((row) => row.id !== id))
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })

    const editedRow = rows.find((row) => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id))
    }
  }

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false }
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await onSave(newRow)
        if (response.status === 200) {
          setSnackbar({
            children: response.message,
            severity: 'success',
          })
          setRows(
            rows.map((row) => (row?.id === newRow?.id ? updatedRow : row))
          )
          refetch({ queryOptions })
          resolve(updatedRow)
        }
      } catch (error) {
        reject(error)
      } finally {
        setLoading(false)
      }
    })
  }

  const columns = getColumns({
    onSave: handleSaveClick,
    onEdit: handleEditClick,
    onDelete: handleDeleteClick,
    onCancel: handleCancelClick,
    getMode: (id) => rowModesModel[id]?.mode,
  })

  return (
    <>
      <KcTable
        layout={CONST_VARIANT.KC_DEFAULT}
        maxHeight={maxHeight}
        rows={rows}
        columns={columns}
        editMode="row"
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        rowCount={rowCountState}
        page={pageNo}
        pageSize={pageSize}
        paginationMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        pagination
        loading={isLoading}
        hideFooterSelectedRowCount
        sortingOrder={['desc', 'asc']}
        initialState={initialState}
        // disableDensitySelector
        components={{
          LoadingOverlay: LinearProgress,
          Pagination: CustomPagination,
          Toolbar: DefaultToolbarContainer,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        componentsProps={{
          pagination: { setRows, setRowModesModel, rows, handleClick },
          noRowsOverlay: { handleClick, text: 'No Configs Available' },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      >
        <Typography
          variant="caption"
          textAlign="right"
          width="100%"
          display="block"
        >
          {CONTENT.general.encrypted_note}
        </Typography>
      </KcTable>
      {!!snackbar && (
        <Snackbar
          open
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
          {...snackbar}
        ></Snackbar>
      )}
    </>
  )
}
