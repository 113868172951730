import React, { createContext, useState } from 'react'
import {
  GoogleOAuthProvider,
  GoogleLogin,
  googleLogout,
} from '@react-oauth/google'

// Mui Components
import Button from '@mui/material/Button'

// Icons
import LogoutOutlined from '@mui/icons-material/LogoutOutlined'

// Redux
import { useDispatch } from 'react-redux'
import { authActions } from '../../slices/auth.slice'

// Utils
import { parseUserInfo } from '../../../utils'
import useDeleteToken from '../../../hooks/useDeleteToken'
import { Spinner } from '../../../layouts/common/Loaders'
import { RoundedNavButton } from '../../../components/common/NavButton/NavButton'

// context to store and prove the auth config to child components
const authConfigContext = createContext(null)
const AuthConfigProvider = authConfigContext.Provider

// Google Oauth Provider
const AuthProvider = ({
  clientId,
  children,
  config = { storageToken: 'user_token' },
}) => {
  return (
    <AuthConfigProvider value={config}>
      <GoogleOAuthProvider clientId={clientId}>{children}</GoogleOAuthProvider>
    </AuthConfigProvider>
  )
}

// Login Button
const LoginButton = ({
  onSuccess = () => {
    console.log('Login Success')
  },
  onError = () => {
    console.log('Login Failed')
  },
}) => {
  const dispatch = useDispatch()


  const handleSuccess = (response) => {
    const token = response?.credential
    // decode jwt and parse required info and store it in redux store
    const userInfo = parseUserInfo(token)
    dispatch(authActions.login(userInfo))

    // trigget the parent callback function
    onSuccess(userInfo)
  }

  return <GoogleLogin onSuccess={handleSuccess} onError={onError} auto_select />
}

// Logout Button
const LogoutButton = ({ children, onLogout = () => {} }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const deleteToken = useDeleteToken()

  // remove token from local storage and update the store
  const handleLogout = async () => {
    try {
      setLoading(true)
      googleLogout()
      const deleteRes = await deleteToken()
      if (deleteRes.status === 200) {
        dispatch(authActions.logout())
        onLogout()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <RoundedNavButton
      component={Button}
      onClick={handleLogout}
      icon={
        <Spinner loading={loading} size="2.5rem" width="2px">
          <LogoutOutlined color="primary" />
        </Spinner>
      }
      displayName={loading ? 'Logging out...' : children}
    ></RoundedNavButton>
  )
}

const GoogleAuth = {
  Provider: AuthProvider,
  LoginButton,
  LogoutButton,
  configContext: authConfigContext,
}

export default GoogleAuth
