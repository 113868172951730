import React from 'react'
import MuiSnackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'

export default function Snackbar({
  open,
  children,
  severity,
  onClose,
  autoHideDuration = 6000,
  position = {
    vertical: 'top',
    horizontal: 'right',
  },
  ...restProps
}) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    onClose(false)
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )
  const { vertical, horizontal } = position

  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      action={action}
      anchorOrigin={{ vertical, horizontal }}
      {...restProps}
    >
      <Alert severity={severity} onClose={handleClose}>
        {children}
      </Alert>
    </MuiSnackbar>
  )
}
