import React, { useContext, useEffect, useState } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useLocalStorage } from '../../hooks'
import { responsiveFontSizes, useMediaQuery } from '@mui/material'
import defaultThemeConfig, {
  generateTheme,
  kardiaBlueThemeConfig,
  kardiaGreenThemeConfig,
} from '../defaultTheme'

// Color mode Context
const ColorModeContext = React.createContext({
  toggleMode: () => {},
  mode: 'light',
})

// hook
export const useThemeMode = () => {
  return useContext(ColorModeContext)
}

const themeSource = {
  default: defaultThemeConfig,
  kardiaGreen: kardiaGreenThemeConfig,
  kardiaBlue: kardiaBlueThemeConfig,
  genreate: (colorCode) => generateTheme(colorCode),
}

// theme provider hook
const useThemeModeProvider = (paletteOverrides) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light')
  const [storedValue, setStoredValue] = useLocalStorage('mode', mode)
  const [themeName, setThemeName] = useState('kardiaBlue')
  const [storedTheme, setStoredTheme] = useLocalStorage('theme', themeName)

  useEffect(() => {
    setMode(storedValue)
    setThemeName(storedTheme || 'kardiaBlue')
  }, [storedValue, storedTheme])

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const updatedValue = prevMode === 'light' ? 'dark' : 'light'
          setStoredValue(updatedValue)
          return updatedValue
        })
      },
    }),
    [setStoredValue]
  )

  const theme = React.useMemo(() => {
    const themeConfig =
      themeSource[themeName] || themeSource.genreate(themeName)
    themeConfig.palette = { ...themeConfig.palette, ...paletteOverrides }
    themeConfig.palette.mode = mode
    return createTheme(themeConfig)
  }, [mode, paletteOverrides, themeName])

  const changeToKardia = (name) => {
    setThemeName(name)
    setStoredTheme(name)
  }

  const themeWithResponsiveFonts = responsiveFontSizes(theme)
  return [colorMode, themeWithResponsiveFonts, changeToKardia, themeName]
}

// Color mode context Provider with Theme Provider

export const ThemeProviderWithMode = ({ children, paletteOverrides = {} }) => {
  const [colorMode, theme, changeToKardia, themeName] =
    useThemeModeProvider(paletteOverrides)
  return (
    <ColorModeContext.Provider
      value={{
        toggleMode: colorMode.toggleColorMode,
        mode: theme.palette.mode,
        changeTheme: changeToKardia,
        themeName,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  )
}
