import React, { useState } from 'react'
import LockIcon from '@mui/icons-material/Lock'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import InputField from '../common/InputField/InputField'
import { useEffect } from 'react'
import { mask } from '../../utils'

const EncryptInput = ({
  masked,
  value,
  rowId,
  name,
  onChange,
  encryptValue,
  readOnly,
  title,
  ...restProps
}) => {
  const [encrypted, setEncrypt] = useState(encryptValue)

  useEffect(() => {
    setEncrypt(encryptValue)
  }, [encryptValue])

  const encryptInput = () => {
    setEncrypt((prevEncrypted) => {
      const isEncrypted = prevEncrypted
      if (!isEncrypted) {
        onChange(
          {
            target: {
              name: 'encrypted',
              value: !isEncrypted,
            },
          },
          rowId
        )
      }
      return !isEncrypted
    })
  }

  const Icon = encrypted ? LockIcon : LockOpenIcon
  return (
    <InputField
      {...restProps}
      value={encrypted ? mask(value) : value}
      rowId={rowId}
      name={name}
      onChange={encrypted ? () => {} : onChange}
      title={readOnly ? title : ''}
      readOnly={readOnly}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={encrypted || readOnly ? undefined : encryptInput}
              sx={{
                cursor: encrypted || readOnly ? 'not-allowed' : 'pointer',
              }}
            >
              <Icon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
export default React.memo(EncryptInput)
