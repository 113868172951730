// React
import React, { useState } from 'react'

// @mui
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'

// Custom component

// Icons
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'

const globalFilters = [
  { title: 'Facility Name', value: 'facility_name' },
  { title: 'Postal Code', value: 'postal_code' },
]

const FacilityGlobalFilterForm = (props) => {
  // const [queryState, setQueryState] = useState({
  //   pageNo: 0,
  //   pageSize: 100,
  //   facility_name: '',
  // })
  const [appliedFilter, setAppliedFilter] = useState(null)
  const [queryString, setQueryString] = useState('')
  const { getFilteredData } = props

  const fetchData = () => {
    getFilteredData(appliedFilter, queryString)
  }

  const filterChange = (value) => {
    if (value === null) {
      setQueryString('')
    }
    setAppliedFilter(value)
  }

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Autocomplete
          sx={{
            '& input': {
              height: '28px',
            },
          }}
          fullWidth
          disablePortal
          size="small"
          id="combo-box-demo"
          options={globalFilters}
          getOptionLabel={(option) => option.title}
          onChange={(event, newValue) => {
            filterChange(newValue)
          }}
          renderInput={(params) => <TextField {...params} label="Search By" />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextField
          sx={{
            '& input': {
              height: '28px',
            },
          }}
          id="query-text"
          label="Query"
          variant="outlined"
          fullWidth
          size="small"
          value={queryString}
          onChange={(event) => {
            setQueryString(event.target.value)
          }}
          disabled={!appliedFilter}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          startIcon={<SearchOutlinedIcon />}
          onClick={fetchData}
          // disabled={!appliedFilter}
          size="large"
        >
          Search
        </Button>
      </Grid>
    </Grid>
  )
}

export default FacilityGlobalFilterForm
