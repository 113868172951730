import { Home } from '@mui/icons-material'
import BreadcrumbTitle from '../components/common/Breadcrumbs/BreadcrumbTitle'
import ROUTES from '../route/routes'
import errors from './content/errors'
import forms from './content/forms'
import pages from './content/pages'

export const FONTS = {
  BASE: 'Roboto, Helvetica, Arial, sans-serif',
  WORK_SANS: 'Work Sans',
}
export const CONTENT = {
  ...pages,
  ...forms,
  ...errors,
}
export const NAVIGATION_DISPLAY_NAME = {
  404: '404',
  403: '403',
  FACILITIES: 'Facilities',
  HOME: 'home',
  GLOBAL_CONFIG: 'Global Config',
}

export const PAGE_TITLE = {
  FACILITIES: 'Interop facilities',
  FACILITIES_ADD: 'Add New Interop Facility',
  FACILITIES_UPDATE: 'Update Interop Facility',
  LOGIN: 'Interop Admin',
  GLOBAL_CONFIG: 'Global Config',
}

export const PATH_PAGE_TITLE_MAP = {
  [ROUTES.ALL.ROOT]: <Home />,
  [ROUTES.ALL.FACILITIES]: <BreadcrumbTitle prop="FACILITIES" />,
  [ROUTES.ALL.FACILITIES_ADD]: <BreadcrumbTitle prop="FACILITIES_ADD" />,
  [ROUTES.ALL.FACILITIES_UPDATE]: <BreadcrumbTitle prop="STORED_TITLE" />,
  [ROUTES.ALL.GLOBAL_CONFIG]: <BreadcrumbTitle prop="GLOBAL_CONFIG" />,
}

export const VALIDATION_RULES = {
  EMAIL: (value, message) => {
    const isEmailValid =
      !!value && /^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    if (!isEmailValid) return message || 'Entered email is not valid'
    return isEmailValid
  },
  NUMBER_MORE_THAN_ZERO: (value, message) => {
    if (!/[0-9]/.test(value)) return 'Only numerical values are allowed'
    return value && value > 0 ? true : message || 'Value should be more than 0'
  },
  REQUIRED: (value, message) => {
    const errorText = message || 'This field is required'
    return String(value).length > 0 ? true : errorText
  },
  ONLY_NUMBER: (value, message) => {
    const isValid = /^[0-9]*$/.test(value)
    return isValid ? true : message || 'Only numerical values are allowed'
  },
  ONLY_LETTERS_NUMBERS_DASHES_UNDERSCORE: (value, message) => {
    const isValid = /^[A-Za-z0-9_-]*$/.test(value)
    return isValid
      ? true
      : message || 'Only letters, digits, underscores, and hyphen are allowed'
  },
  MUST_START_WITH_LETTER: (value, message) => {
    const isValid = /^[a-zA-Z].*$/.test(value)
    return isValid ? true : message || 'Must begin with a letter'
  },
}

const constants = { NAVIGATION_DISPLAY_NAME, CONTENT, VALIDATION_RULES }
export default constants
