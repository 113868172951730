export const BASE_URL = process.env.REACT_APP_API_URL

export const API_URL = {
  LOGIN: 'v1/auth/google/',
  REFRESH: 'v1/auth/google/refresh/',
  DELETE: 'v1/auth/google/del/',
  FACILITIES: 'v1/facilities',
  FACILITY_CONFIG: 'v1/facilityConfig',
  TEAMS: 'v1/teams',
  GLOBAL_CONFIG: 'v1/globalConfig'
}

export const METHODS = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
  PATCH: 'PATCH'
}

export const ACTIONS = {
  INIT: 'INIT',
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
}

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  COMPLETED: 'completed',
  FAILED: 'failed'
}
