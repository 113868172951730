import { FONTS } from "../constants/constants"

const defaultThemeConfig = {
  palette: {
    mode: 'dark',
    custom: {
      light: '#F5F5F5',
      main: '#000',
      dark: '#050505',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
  },
  dimentions: {
    header: 88,
    footer: 76,
    drawerWidth: 240,
    avatar: {
      size: {
        open: 70,
        close: 35,
      },
    },
    teamProfile: {
      heigth: 200,
    },
  },
}
export const kardiaBlueThemeConfig = {
  ...defaultThemeConfig,
  palette: {
    ...defaultThemeConfig.palette,
    primary: {
      light: '#96AFDB',
      main: '#6387C5',
      dark: '#174E8C'
    },
  },
  typography: {
    fontFamily: `${FONTS.WORK_SANS} ${FONTS.BASE}`
  },
}

export const kardiaGreenThemeConfig = {
  ...defaultThemeConfig,
  palette: {
    ...defaultThemeConfig.palette,
    primary: {
      main: '#067F6F',
    },
  },
}

export const generateTheme = (colorCode) => ({
  ...defaultThemeConfig,
  palette: {
    ...defaultThemeConfig.palette,
    primary: {
      main: colorCode,
    },
  },
})

export default defaultThemeConfig
