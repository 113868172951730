import React from 'react'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import MuiLink from '@mui/material/Link'

import { Link, useMatches } from 'react-router-dom'

const Breadcrumbs = () => {
  let matches = useMatches()
  
  let crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => match.handle.crumb(match.data))

  return (
    <MuiBreadcrumbs
      sx={{
        marginLeft: 3,
      }}
    >
      {crumbs.map((crumb, index) => {
        const isLast = index === crumbs.length - 1

        // last item shouldn't be a link
        return isLast ? (
          <Typography key={index} textTransform='uppercase'>{crumb?.title}</Typography>
        ) : (
          <MuiLink component={Link} to={crumb?.path} key={index} textTransform='uppercase'>
            {crumb?.title}
          </MuiLink>
        )
      })}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumbs
