import React from 'react'

import Box from '@mui/material/Box'
import FormHelperText  from '@mui/material/FormHelperText'
import { useTheme }  from '@mui/material'

import { GridEditInputCell } from '@mui/x-data-grid-premium'

function CustomEditInputCell(props) {
  const { error, hasFocus, ...rest } = props
  const finalProps = { error: !!error, hasFocus, ...rest }
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        '& .MuiInputBase-root': {
          height: '100%',
        },
        '& .MuiFormHelperText-root': {
          position: 'absolute',
        },
        '& .Mui-error.MuiInputBase-root': {
          outline: hasFocus
            ? `1px solid ${theme.palette.error[theme.palette.mode]}`
            : 'none',
          color: theme.palette.error[theme.palette.mode],
        },
      }}
    >
      <GridEditInputCell {...finalProps} />
      {hasFocus && error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}

export default CustomEditInputCell