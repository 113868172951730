import React from 'react'

// MUI Components
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined'

// Constants
import ROUTES from '../../route/routes'
import CONSTANTS from '../../constants'
import { SettingsTwoTone } from '@mui/icons-material'

const SIDE_NAV_CONFIG = [
  {
    to: ROUTES.ALL.GLOBAL_CONFIG,
    displayName: CONSTANTS.NAVIGATION_DISPLAY_NAME.GLOBAL_CONFIG,
    icon: <SettingsTwoTone color="primary" />,
  },
  {
    to: ROUTES.ALL.FACILITIES,
    displayName: CONSTANTS.NAVIGATION_DISPLAY_NAME.FACILITIES,
    icon: <MedicalServicesOutlinedIcon color="primary" />,
  },
]

export default SIDE_NAV_CONFIG
