import React from 'react'

// MUI Components
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// Svg assest
import WifiOffIcon from '@mui/icons-material/WifiOff';

// Constants
import { CONTENT } from '../../constants/constants'
import Layout from '../../layouts/common/Layout'

const Offline = () => {
  const { offline } = CONTENT
  return (
    <Layout.CenterWrapper>
      <Stack direction="column" alignItems="center" marginY={8}>
        <WifiOffIcon sx={{
          width: '8em',
          height: '8em',
        }}/>
        <Typography variant="h2" fontWeight={600} marginY={2}>
          {offline.title}
        </Typography>
        <Typography variant="subtitle1" marginBottom={2}>
          {offline.subtitle}
        </Typography>
      </Stack>
    </Layout.CenterWrapper>
  )
}

export default Offline
