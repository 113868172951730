import { useState, useEffect } from 'react'

const useOnline = () => {
  const [online, setOnline] = useState(true)
  // Function to handle online status changes
  function handleOnline() {
    // Perform actions when the user is online
    setOnline(true)
  }

  // Function to handle offline status changes
  function handleOffline() {
    // Perform actions when the user is offline
    setOnline(false)
  }

  useEffect(() => {
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  return online
}

export default useOnline
