// React
import React from 'react'

// @mui
import { GridActionsCellItem } from '@mui/x-data-grid-premium'

// Custom component
import { KcTable } from '../common/kc-table'
import {
  SUPPORTED_LOCALE,
  LOCALE_DEFAULT_TEXT,
  IconComboCellRenderer,
} from '../common/kc-table'
import Switch from '../common/Switch'

// Icons
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import EditIcon from '@mui/icons-material/ModeEditOutlined';

import { useNavigate } from 'react-router-dom'
import ROUTES from '../../route/routes'
import { CONST_VARIANT } from '../common/kc-table/constants'

const FacilityList = (props) => {
  const { data, onStatusChange } = props
  const navigate = useNavigate()
  // Grid columns
  const columns = [
    { field: 'facility_name', headerName: 'Facility Name', minWidth: 320, flex: 1 },
    {
      field: 'connected_application',
      headerName: 'Connected Application',
      minWidth: 320,
      flex: 1,
    }, // TODO: update the field-json mapping
    // { field: 'city', headerName: 'City', width: 120, flex: 1 },
    { field: 'country', headerName: 'Country', minWidth: 150, flex: 0.5 },
    {
      field: 'facility_code',
      headerName: 'Facility Code',
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: 'practice_timezone',
      headerName: 'Practice Timezone',
      minWidth: 250,
      flex: 0.5,
    },
    {
      field: '_active',
      headerName: 'Status',
      minWidth: 180,
      flex: 0.5,
      type: 'boolean',
      cellClassName: 'data-grid__active',
      renderCell: (params) => {
        return (
          <IconComboCellRenderer
            params={params}
            badgePane={
              <Switch
                checked={!!params?.value}
                alignment="start"
                onText="Active"
                offText="Inactive"
                showOnlyActiveText
                textTransform="none"
                toggle={(checked) => onStatusChange(checked, params)}
                textVisibilityPerViewPort={{
                  xs: 'none',
                }}
              />
            }
          />
        )
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      cellClassName: 'data-grid__actions',
      width: 90,
      disableExport: true,
      filterable: false,
      headerClassName: 'custom-action__header',
      renderCell: (params) => {
        const { id } = params
        return [
          <GridActionsCellItem
            sx={{
              padding: 1.25,
              color: 'inherit',
              '& .MuiSvgIcon-root': {
                fontSize: '1.6rem'
              },
            }}
            icon={<VisibilityIcon/>}
            label="VIEW"
            key={`${id}-VIEW`}
            onClick={() => renderFacilityDetails('VIEW', params)}
            color="inherit"
          />,
          <GridActionsCellItem
            sx={{
              padding: 1.25,
              color: 'inherit',
              '& .MuiSvgIcon-root': {
                fontSize: '1.6rem'
              }
            }}
            icon={<EditIcon />}
            label="Edit"
            key={`${id}-EDIT`}
            className="textPrimary"
            onClick={() => renderFacilityDetails('EDIT', params)}
            color="inherit"
          />,
        ]
      },
    },
  ]

  const LOCALE = {
    ...LOCALE_DEFAULT_TEXT.enUS,
    noRowsLabel: 'No Facilities Found...',
  }

  const renderFacilityDetails = (mode = 'VIEW', data = {}) => {
    navigate(`${ROUTES.ALL.FACILITIES}/update/${data?.row?.id}`, {
      state: {
        mode,
      },
    })
  }
  return (
    <KcTable
      initialState={{
        sorting: {
          sortModel: [{ field: '_active', sort: 'desc' }],
        },
      }}
      rows={data.facilities}
      columns={columns}
      defaultLocaleLanguage={SUPPORTED_LOCALE.English}
      defaultLocaleText={LOCALE}
      pagination
      layout={CONST_VARIANT.KC_DEFAULT}
      disableSelectionOnClick
      // disableDensitySelector
      autoHeight={data.facilities.length <= 10}
    />
  )
}

export default FacilityList
