import React, { useEffect, useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import { useAxiosPrivate, useFetch } from '../../../hooks'
import { useTheme } from '@emotion/react'
import { matchSorter } from 'match-sorter'
import { VALIDATION_RULES } from '../../../constants/constants'

const isEmpty = (value) =>
  typeof value === 'object' ? Object.values(value).every((val) => !val) : !value

const isExist = (dataSourceList, property, value) => {
  return dataSourceList.find((dataSource) => dataSource[property] === value)
}

const canIncludeInitialOption = (dataSourceList, property, value) =>
  !isExist(dataSourceList, property, value)

const OptionItem = (props) => {
  const { optionLabelProp, optionItemProps, searchResult } = props
  const theme = useTheme()
  const optionLabels =
    typeof optionLabelProp === 'object' ? (
      <ListItemText
        primary={searchResult[optionLabelProp?.primary]}
        secondary={searchResult[optionLabelProp?.secondary]}
      />
    ) : (
      <ListItemText primary={searchResult[optionLabelProp]} />
    )

  return (
    <ListItem
      {...optionItemProps}
      sx={{
        // to fix hover issue
        '&:hover': {
          background: theme.palette.action.hover,
        },
        display: isEmpty(searchResult) ? 'none' : 'initial',
      }}
    >
      {optionLabels}
    </ListItem>
  )
}
const LiveSearch = (props) => {
  const {
    url,
    name,
    targetName,
    value,
    targetValue,
    rowId,
    idProp = 'id',
    optionLabelProp,
    searchQueryProp,
    onSelect,
    onError,
    valid,
    error,
    dataProperty,
    noOptionsText,
    readOnly,
    hint,
    ...restProps
  } = props
  if (!name) throw Error('name prop is required')

  if (!url) {
    throw new Error('url prop is required')
  }
  const theme = useTheme()
  const isDarkMode = theme?.palette?.mode === 'dark'
  const [searchResults, setSearchResults] = useState([])
  const [inputVal, setInputValue] = useState(null)
  const [touched, setTouched] = useState(false)
  const axiosPrivate = useAxiosPrivate() 
  // fetching the teams data
  const [loading, , data] = useFetch({
    axiosInstance: axiosPrivate,
    path: url,
  })

  const handleError = (inputValue, targetInputValue) => {
    onError(name, VALIDATION_RULES.REQUIRED(inputValue), rowId)
    onError(targetName, VALIDATION_RULES.REQUIRED(targetInputValue), rowId)
  }
  useEffect(() => {
    const compoundValue = {
      [optionLabelProp.primary]: value,
      [optionLabelProp.secondary]: targetValue,
    }
    setInputValue(compoundValue)
    handleError(value, targetValue)

    // eslint-disable-next-line
  }, [value, targetValue])

  useEffect(() => {
    if (data) {
      const dataSource = data[dataProperty]
      setSearchResults(
        canIncludeInitialOption(dataSource, idProp, value)
          ? [inputVal, ...dataSource]
          : dataSource
      )
    }
    // eslint-disable-next-line
  }, [data, dataProperty])

  const handleSelect = (newValue) => {
    let inputValue
    let targetInputValue
    let practice_timezone
    let connected_application
    if (newValue) {
      inputValue = newValue[optionLabelProp?.primary]
      targetInputValue = newValue[optionLabelProp?.secondary]
      practice_timezone = newValue["practice_timezone"]
      connected_application = newValue["connected_application"]
    } else {
      inputValue = ''
      targetInputValue = ''
      practice_timezone = ''
      connected_application = ''
    }
    onSelect(inputValue, targetInputValue, practice_timezone, connected_application, rowId)
    handleError(inputValue, targetInputValue)
  }

  const handleOnBlur = (e) => {
    setTouched(true)
    const match = searchResults.some((result) => {
      const values = Object.values(result)
      return values.some((value) => value && value === e.target.value)
    })
    onError(name, match ? true : 'Please select a value', rowId)
  }
  const finalOptionLabelProp =
    typeof optionLabelProp === 'object'
      ? optionLabelProp?.primary
      : optionLabelProp

  const filterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue, {
      keys: inputVal ? Object.keys(inputVal) : [searchQueryProp],
    })
  }

  return (
    <Autocomplete
      clearOnBlur
      onBlur={handleOnBlur}
      readOnly={readOnly}
      loading={loading}
      loadingText="loading"
      value={inputVal}
      onChange={(event, newValue) => {
        setInputValue(newValue)
        handleSelect(newValue)
      }}
      autoHighlight
      id="live-search"
      getOptionLabel={(searchResult) => searchResult[finalOptionLabelProp]}
      filterOptions={filterOptions}
      options={searchResults}
      isOptionEqualToValue={(option, value) => {
        return option[searchQueryProp] === value[searchQueryProp]
      }}
      noOptionsText={noOptionsText}
      renderOption={(props, searchResult) => {
        // when the initial value is empty, we will include the empty value in the results to suppress the warning,
        // but the value will be omitted from the options
        if (isEmpty(searchResult)) return null
        return (
          <OptionItem
            optionLabelProp={optionLabelProp}
            optionItemProps={props}
            searchResult={searchResult}
            key={searchResult[idProp]}
          />
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...restProps}
          name={name}
          error={touched && error && error !== true}
          helperText={touched && error !== true ? error : hint}
          fullWidth
          InputProps={{
            readOnly: readOnly,
            ...params.InputProps,
          }}
          inputProps={{
            ...params.inputProps,
          }}
          sx={{
            '& input': {
              cursor: readOnly ? 'not-allowed' : 'initial',
              backgroundColor: readOnly
                ? `${isDarkMode
                  ? theme.palette.grey[900]
                  : theme.palette.grey[50]
                }`
                : 'initial',
            },
            '& .MuiFormHelperText-root': {
              position: 'absolute',
              bottom: '-1.6em',
              marginLeft: 0,
            },
          }}
        />
      )}
    />
  )
}

export default LiveSearch
