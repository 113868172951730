import React from 'react'
import ReactDOM from 'react-dom/client'

// Google Auth
import GoogleAuth from './auth/components/GoogleAuth'

// Redux
import { Provider } from 'react-redux'
import store from './state/store'

// Diaable dev tools on production
import { disableReactDevTools } from '@fvilers/disable-react-devtools'

import './index.css'
import App from './App'

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}
const appRoot = ReactDOM.createRoot(document.getElementById('app-root'))

appRoot.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleAuth.Provider
        clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
      >
        <App />
      </GoogleAuth.Provider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
