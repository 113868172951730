import React, { useMemo, useState } from 'react'

import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'

import { CONTENT } from '../../constants/constants'
import { charSet, guid } from '../../utils'

import Form from '../common/Form'
import FormHeader from '../common/FormHeader'
import { useAxiosPrivate } from '../../hooks'
import { request } from '../../api/axios'
import { API_URL } from '../../api/constants'
import InputFieldWithAction from '../InputFieldWithAction'
import LoadingButton from '../common/LoadingButton'
import Snackbar from '../common/Snackbar'

const getRequiredFieldsData = (fields, dataSource) =>
  fields.reduce((requiredData, currField) => {
    const { name } = currField
    return { ...requiredData, [name]: dataSource[name] || '' }
  }, {})

const generateCode = (length, charSet) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const newGuid = guid(length, charSet)
      resolve(newGuid)
    }, 500)
  })
}

// const syncFacilityNameAndTimezoneWithKardiaAdmin = async (teamId, axiosInstance, searchProp = 'name') => {
//   const response = await request({
//     axiosInstance,
//     path: `${API_URL.TEAMS}/${teamId}`,
//   })
//   if (response.status === 200) {
//     return response.data[searchProp]
//   }
// }

const syncInteropAdminWithKardiaAdmin = async (teamId, axiosInstance) => {
  const response = await request({
    axiosInstance,
    path: `${API_URL.TEAMS}/${teamId}`,
  })
  if (response.status === 200) {
    return response.data
  }
}

const FacilityDetailsForm = ({
  prefillData,
  loading,
  error,
  onSubmit,
  readOnlyFields,
  viewMode,
  triggerOnMountFields = []
}) => {
  const axiosPrivate = useAxiosPrivate()

  const [teamId, setTeamId] = useState(prefillData?.kpro_team_id || null)
  const [setsyncInProgress, setSetsyncInProgress] = useState(false)
  const [syncError, setSyncError] = useState(null)

  const [facilityDataFromKardiaAdmin, setfacilityDataFromKardiaAdmin] = useState({})
  const syncWithKardiaAdminData = async () => {
    try {
      setSetsyncInProgress(true)
      if (!teamId) throw new Error("NO_TEAM_ID")
      const response = await syncInteropAdminWithKardiaAdmin(teamId, axiosPrivate)
      setfacilityDataFromKardiaAdmin({
        facility_name: response.name,
        connected_application: response.connected_application,
        practice_timezone: response.practice_timezone,
        kpro_team_id: response.kpro_id,
      })
    } catch (error) {
      if (error.message === "NO_TEAM_ID") {
        setSyncError("Please select the KPro Team to sync the data")
      } else {
        setSyncError("Something went wrong")
      }
    } finally {
      setSetsyncInProgress(false)
    }
  }
  const fields = useMemo((memo) => {
    return [
      {
        label: 'K-Pro-Team',
        name: 'kpro_team_id',
        hint: 'Search with K-Pro Team Id or Facility Name',
        required: true,
      },
      {
        label: 'Facility Name',
        name: 'facility_name',
        required: true,
      },
      {
        label: 'Street',
        name: 'street',
      },
      {
        label: 'Practice timezone',
        name: 'practice_timezone',
        required: true,
      },
      {
        label: 'City',
        name: 'city',
      },
      {
        label: 'State',
        name: 'state',
      },
      {
        label: 'Country',
        name: 'country',
      },
      {
        label: 'Postal Code',
        name: 'postal_code',
      },
      {
        label: 'Connected Application',
        name: 'connected_application',
      },
      {
        label: 'Facility Code',
        name: 'facility_code',
        component: (params) => (
          <InputFieldWithAction
            {...params}
            triggerOnMount={triggerOnMountFields.includes(params?.name)}
            action={() => generateCode(5, charSet.ALPHA_UPPER)}
          />
        ),
      },
      {
        label: 'X-API-Key',
        name: 'xapi_key',
        charSet: charSet.ALPHANUMERIC,
        length: 32,
        component: (params) => (
          <InputFieldWithAction
            {...params}
            triggerOnMount={triggerOnMountFields.includes(params?.name)}
            action={() => generateCode(32, charSet.ALPHANUMERIC)}
          />
        ),
      },
    ]
  }, [triggerOnMountFields])

  const initialData = useMemo(() => {
    return prefillData
      ? getRequiredFieldsData(fields, {
          ...prefillData,
          ...facilityDataFromKardiaAdmin,
        })
      : getRequiredFieldsData(fields, {
          ...facilityDataFromKardiaAdmin,
        })
  }, [prefillData, facilityDataFromKardiaAdmin, fields])

  const isViewMode = viewMode === 'VIEW'

  return (
    <Paper
      sx={{
        padding: 3,
      }}
    >
      <FormHeader
        marginBottom={2}
        {...CONTENT.facility_details}
        action={
          <LoadingButton
            type="button"
            variant="outlined"
            disabled={setsyncInProgress || isViewMode}
            loading={setsyncInProgress}
            onClick={syncWithKardiaAdminData}
          >
            Sync Facility With Kardia Admin
          </LoadingButton>
        }
      />
      <Divider />
      <Form
        fields={fields}
        initialData={initialData}
        getTeamId={(teamId) => setTeamId(teamId)}
        readOnlyFields={readOnlyFields}
        columnCount={2}
        onSubmit={onSubmit}
        loading={loading }
        error={error}
        viewMode={viewMode}
        disabled={setsyncInProgress || isViewMode}
      />
      <Snackbar
        open={!!syncError}
        children={syncError}
        severity='error'
        onClose={() => setSyncError(null)}
      />
    </Paper>
  )
}

export default FacilityDetailsForm
