import React, { useEffect, useState, useRef } from 'react'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import { STATUS } from '../../api/constants'
import { CONTENT, VALIDATION_RULES } from '../../constants/constants'
import { guid } from '../../utils'

import FormHeader from '../common/FormHeader'
import EncryptInput from '../EncryptInput'
import Form from '../common/Form'

const BASE_FIELDS = [
  {
    name: 'key',
    label: 'Key',
    validator: [
      VALIDATION_RULES.REQUIRED,
      VALIDATION_RULES.ONLY_LETTERS_NUMBERS_DASHES_UNDERSCORE,
      VALIDATION_RULES.MUST_START_WITH_LETTER,
    ],
    title:
      CONTENT.general.encrypted_note,
  },
  {
    name: 'value',
    label: 'Value',
    component: EncryptInput,
    required: true,
    title:
      CONTENT.general.encrypted_note,
  },
  {
    name: 'comment',
    label: 'Comment',
    title:
      CONTENT.general.encrypted_note,
  },
]

const preparePayload = (data, localRows = []) => {
  return Object.keys(data).map((id) => {
    const { key, value, encrypted, comment = '' } = data[id]
    return {
      id: localRows.includes(id) ? null : id,
      key,
      value,
      encrypted: !!encrypted,
      comment,
    }
  })
}

const formatFieldData = (data) => {
  const { key, value, encrypted, comment, created_at } = data
  return {
    key,
    value,
    encrypted,
    comment: comment || '',
    created_at
  }
}

const getRequiredRowsData = (dataSource = {}) => {
  const newRows = dataSource?.facilitiyConfig?.map((config) => config?.id)
  const formData = dataSource?.facilitiyConfig?.reduce(
    (finalData, currentRowData) => {
      const { id, ...restRowData } = currentRowData
      return {
        ...finalData,
        [id]: formatFieldData(restRowData),
        
      }
    },
    {}
  )
  return [newRows, formData]
}

const isValueExist = (dataList, value) =>
  dataList.find((dataValue) => dataValue === value)

const FacilityConfigForm = ({
  loading,
  prefillData,
  onSubmit,
  onDelete,
  viewMode,
  status,
}) => {
  const [rows, setRows] = useState([])
  const [localRows, setLocalRows] = useState([])

  const initialData = useRef({})
  useEffect(() => {
    if (status === STATUS.COMPLETED) {
      setLocalRows([])
    }
  }, [status])

  useEffect(() => {
    if (prefillData?.facilitiyConfig) {
      const [newRows, newRowsData] = getRequiredRowsData(prefillData)
      initialData.current = newRowsData
      setRows(newRows)
    }
  }, [prefillData])

  const addRow = (newRowId) => {
    setLocalRows((existingRows) => [...existingRows, newRowId])
    setRows((existingRows) => [...existingRows, newRowId])
  }

  const deleteRow = async (id) => {
    const updatedRows = rows.filter((row) => row !== id)
    setRows(updatedRows)

    // avoiding api request on local state update
    if (!isValueExist(localRows, id)) {
      onDelete(id)
    }
  }

  const handleSubmit = (formData) => {
    onSubmit(preparePayload(formData, localRows))
  }

  const handleAddRow = (id) => {
    const newRowId = guid()
    addRow(newRowId)
  }

  const handleDelete = (id) => {
    deleteRow(id)
  }

  const fields = React.useMemo(() => {
    return rows.map((id) => ({
      id,
      created_at: initialData.current[id]?.created_at,
      fields: BASE_FIELDS,
    }))
  }, [rows])

  const sortedFields = React.useMemo(() => {
    return fields.sort(function(a,b){
      return a?.created_at && b?.created_at ? new Date(a.created_at) - new Date(b.created_at): 0
    })
  }, [fields])

  return (
    <Paper
      sx={{
        padding: 3,
        marginTop: 3,
      }}
    >
      <FormHeader marginBottom={2} {...CONTENT.facility_configs} >
      <Button
          variant="contained"
          onClick={handleAddRow}
          disabled={viewMode === 'VIEW'}
          size="large"
        >
          Add config
        </Button>
      </FormHeader>
      <Divider />
      <Form
        fields={sortedFields}
        columnCount={3}
        initialData={initialData.current}
        onDelete={handleDelete}
        onSubmit={handleSubmit}
        loading={loading}
        viewMode={viewMode}
      >
      </Form>
      <Typography
        variant="caption"
        textAlign="right"
        width="100%"
        display="block"
        sx={{
          marginTop: {
            xs: 2,
          },
        }}
      >
        {CONTENT.general.encrypted_note}
      </Typography>
    </Paper>
  )
}

export default FacilityConfigForm
