import { useGoogleLogin } from '@react-oauth/google'
import { useDispatch } from 'react-redux'

import useAuth from './useAuth'

import { parseUserInfo } from '../../utils'
import { axiosPrivate } from '../../api/axios'
import { API_URL } from '../../api/constants'

// A hook enable google on tab login

const useGoogleLoginWithToken = (onSuccess, onError) => {
  const dispatch = useDispatch()
  // login action
  const { login } = useAuth()

  const handleSuccess = (token) => {
    const userInfo = parseUserInfo(token)
    if (userInfo.emailVerified) {
      dispatch(login(userInfo))
      onSuccess(userInfo)
    }
  }

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    hosted_domain: 'alivecor.com',
    scope: ['https://www.googleapis.com/auth/userinfo.profile'],
    onSuccess: async (codeResponse) => {
      try {
        const response = await axiosPrivate.get(API_URL.LOGIN, {
          params: {
            code: codeResponse.code,
          }
        })
        const data = response?.data
        if (data?.id_token) {
          window.localStorage.setItem('expiry_time', data?.expires_in)
          handleSuccess(data?.id_token)
        } else {
          throw new Error('token not found')
        }
      } catch (error) {
        onError(error)
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  })

  return googleLogin
}
export default useGoogleLoginWithToken
