import React, { useState, useEffect } from 'react'

// custom component
import PlaceholderText from '../../components/common/SampleText'
import Layout from '../../layouts/common/Layout'
import FacilityList from '../../components/FacilityList/FacilityList'
import FacilityGlobalFilterForm from '../../components/FacilityGlobalFilterForm/FacilityGlobalFilterForm'

import { METHODS, errorHandler } from '../../hooks/useFetch'

// constants
import { API_URL } from '../../api/constants'

// css
import './facilities.css'
import { useAxiosPrivate, useDocumentTitle } from '../../hooks'
import { PAGE_TITLE } from '../../constants/constants'
import Loader from '../../components/common/Loader'
import { Paper } from '@mui/material'
import { request } from '../../api/axios'

const Facilities = () => {
  const [facilities, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  // updating the page title
  useDocumentTitle(PAGE_TITLE.FACILITIES)
  const axiosPrivate = useAxiosPrivate()

  const fetchData = async (param) => {
    const PATH = API_URL.FACILITIES
    try {
      const response = await axiosPrivate[METHODS.GET.toLowerCase()](PATH, {
        params: param,
      })
      setData(response.data)
      setError(null)
    } catch (error) {
      setError(errorHandler(error))
    } finally {
      setLoading(false)
    }
  }

  const fetchFilteredFacilities = async (filterParam, query) => {
    const customParams = {
      pageNo: 0,
      pageSize: 100,
    }
    if (filterParam) {
      customParams[filterParam.value] = query
    }
    fetchData(customParams)
  }

  useEffect(() => {
    fetchData({
      pageNo: 0,
      pageSize: 100,
    })
    // eslint-disable-next-line
  }, [])

  const handleStatusChange = async (checked, params) => {
    try {
      const response = await request({
        axiosInstance: axiosPrivate,
        path: `${API_URL.FACILITIES}/${params?.row?.id}`,
        method: METHODS.PUT,
        data: {
          active: !params?.row?._active,
        },  
      })
      if (response.status === 200) {
        console.log()
        fetchData({
          pageNo: 0,
          pageSize: 100,
        })
      }
    } catch (error) {}
  }

  return (
    <Layout.CenterWrapper>
      {loading || error?.status === 421 ? (
        <Loader />
      ) : error ? (
        <PlaceholderText>Error</PlaceholderText>
      ) : (
        <React.Fragment>
          <Paper
            className="global-filter-panel"
            sx={{
              padding: 3,
              marginBottom: 3,
            }}
          >
            <FacilityGlobalFilterForm
              getFilteredData={fetchFilteredFacilities}
            />
          </Paper>
          <div className="grid-panel">
            <FacilityList
              data={facilities}
              onStatusChange={handleStatusChange}
            />
          </div>
        </React.Fragment>
      )}
    </Layout.CenterWrapper>
  )
}

export default Facilities
