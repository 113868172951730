import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../auth/hooks/useAuth'
import ROUTES from '../route/routes'

// Checks if the user logged-in and redirect him to protected home page
// use case: when a logged in user accessing the login page

const useUserLoggedIn = () => {
  const navigate = useNavigate()
  const { auth } = useAuth()

  useEffect(() => {
    const token = auth?.user?.token
    if (token) {
      navigate(ROUTES.ALL.GLOBAL_CONFIG, {
        replace: true,
      })
    }
  }, [navigate, auth?.user?.token])
}

export default useUserLoggedIn
