const PROTECTED_ROUTES = {
  ROOT: '/',
  DASHBOARD: '/dashboard',
  FACILITIES: '/facilities',
  FACILITIES_ADD: '/facilities/add',
  FACILITIES_UPDATE: '/facilities/update/:facilityId',
  FORBIDDEN: '/forbidden',
  GLOBAL_CONFIG:'/config'
}

const PUBLIC_ROUTES = {
  LOGIN: '/login',
}

const ROUTES = {
  PROTECTED: PROTECTED_ROUTES,
  PUBLIC: PUBLIC_ROUTES,
  ALL: { ...PUBLIC_ROUTES, ...PROTECTED_ROUTES }
}
export default ROUTES
