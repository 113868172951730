import React from 'react'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

// css

const IconComboCellRenderer = (props) => {
  const { params, text, badgePane, onClickHandle, className } = props

  const badgePaneClick = (e) => {
    onClickHandle !== undefined && onClickHandle(params)
  }

  return (
    <>
      {text && text !== '' && text !== null && <Typography>{text}</Typography>}
      {badgePane && badgePane !== null && (
        <Box
          className={className}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
          }}
          onClick={(e) => badgePaneClick(e)}
        >
          {badgePane}
        </Box>
      )}
    </>
  )
}

export default IconComboCellRenderer
