import React from 'react'
import NotFound from '../../pages/NotFound'
import Facilities from '../../pages/Facilities'
import ROUTES from '../routes'
import Forbidden from '../../pages/Forbidden'
import Login from '../../pages/Login'
import { Navigate } from 'react-router-dom'
import CreateFacility from '../../pages/CreateFacility'
import UpdateFacility from '../../pages/UpdateFacility'
import GlobalConfig from '../../pages/GlobalConfig/GlobalConfig'

/**
 * TODO:
 * useRouteAuthorizationChecker will receive permission and practice name (RPM / IDTF / CRP / TK)
 * each practice have set of routes in contants file
 */

// A hook will return a function
const useRouteAuthorizationChecker = (permissions, practice) => (route) => {
  const { isPhysician, isAdmin } = permissions

  // user permission logic can go @here
  let returnComponent = null
  // anything out of Navigation shall route to default / 404 (Not Found page)
  switch (route) {
    case ROUTES.ALL.ROOT:
      returnComponent = <Navigate to={ROUTES.ALL.LOGIN} />
      break
    case ROUTES.ALL.FACILITIES:
      returnComponent = isPhysician && isAdmin ? <Facilities /> : <Facilities />
      break
    case ROUTES.ALL.FACILITIES_ADD:
      returnComponent = <CreateFacility />
      break
    case ROUTES.ALL.FACILITIES_UPDATE:
      returnComponent = <UpdateFacility />
      break
    case ROUTES.ALL.GLOBAL_CONFIG:
        returnComponent = <GlobalConfig />
        break  
    case ROUTES.ALL.FORBIDDEN:
      returnComponent = <Forbidden />
      break
    case ROUTES.ALL.LOGIN:
      returnComponent = <Login />
      break
    default:
      returnComponent = <NotFound />
      break
  }

  return returnComponent
}

export default useRouteAuthorizationChecker
