import { useEffect } from 'react'
import WebFont from 'webfontloader'
import { loadDefaultFonts } from '../utils'

// pass the list of Google font name https://fonts.google.com/

const useLoadFonts = (fonts = ['Roboto:300,400,500,700'], all = false) => {
  let localFonts = fonts

  if (all) {
    localFonts = fonts.map((font) => `${font}:300,400,500,700`)
  }
  useEffect(() => {
    try {
      WebFont.load({
        google: {
          families: [...localFonts],
        },
      })
    } catch (error) {
      loadDefaultFonts()
      console.log(error)
    }
  }, [localFonts])
}

export default useLoadFonts
