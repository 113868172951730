import React, { useCallback, useState } from 'react'

// MUI Components
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

// Custom components
import { DrawerWithRoundedNavButton } from '../common/Drawer/Drawer'
import TeamProfile from '../TeamProfile/TeamProfile'
import NavList from '../common/NavList/NavList'

// Theme component
import { useTheme } from '@mui/material/styles'

import logo from '../../assets/images/kardia_logo_blue.png'

const Sidebar = ({ bottomMenu, logoutButton }) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()

  // a call back function to get the sidebar open state from drawer component
  const handleOpenSidebar = useCallback((open) => {
    setOpen(open)
  }, [])

  return (
    <DrawerWithRoundedNavButton onOpen={handleOpenSidebar} elevation={1}>
      <TeamProfile
        open={open}
        teamInfo={{
          src: logo,
          alt: "AliveCor's logo",
        }}
      >
        <Typography
          sx={{
            textTransform: 'uppercase',
            letterSpacing: '2.5px',
            fontWeight: 'bold',
          }}
          variant="h6"
          color="primary"
        >
          Interop
        </Typography>
        <Typography
          sx={{
            textTransform: 'uppercase',
            letterSpacing: '2.5px',
          }}
          variant="overline"
        >
          Admin
        </Typography>
      </TeamProfile>

      <NavList open={open} />

      {/* Optional Bottom nav menu  */}
      <Stack
        sx={{
          marginTop: 'auto',
          py: theme.spacing(1),
        }}
        direction="column"
      >
        <Divider />

        {bottomMenu && bottomMenu}

        {/* Logout Button */}
        {logoutButton && logoutButton}
      </Stack>
    </DrawerWithRoundedNavButton>
  )
}

export default Sidebar
