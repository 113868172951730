import React from 'react'
import MuiSwitch from '@mui/material/Switch'
import { Typography } from '@mui/material'
import { Box } from '@mui/material'
import { useState } from 'react'
import { useEffect } from 'react'

export const ALIGNMENT = {
  START: 'start',
  CENTER: 'center',
  END: 'end',
}

const ALIGNMENT_MAP = {
  [ALIGNMENT.START]: 'flex-start',
  [ALIGNMENT.CENTER]: 'center',
  [ALIGNMENT.END]: 'flex-end',
}
const Switch = ({
  toggle = () => {},
  checked,
  onText,
  offText,
  alignment = 'end',
  sx,
  textTransform = 'uppercase',
  showOnlyActiveText = false,
  variant,
  textVisibilityPerViewPort = {},
}) => {
  const [check, setCheck] = useState(checked)
  useEffect(() => {
    setCheck(checked)
  }, [checked])
  const activeState = check ? 'on' : 'off'
  const canShowOffText = showOnlyActiveText
    ? offText && activeState === 'off'
    : offText
  const canShowOnText = showOnlyActiveText
    ? onText && activeState === 'on'
    : onText

  const colorPrimary = variant || 'primary'
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: ALIGNMENT_MAP[alignment],
        flexDirection:
          showOnlyActiveText && activeState === 'off' ? 'row-reverse' : 'row',
        ...sx,
      }}
    >
      {canShowOffText && (
        <Typography
          textTransform={textTransform}
          sx={{
            display: {
              lg: 'block',
              ...textVisibilityPerViewPort,
            },
          }}
          color={
            showOnlyActiveText
              ? check
                ? colorPrimary
                : 'inherit'
              : !check
              ? colorPrimary
              : 'inherit'
          }
        >
          {offText}
        </Typography>
      )}
      <MuiSwitch
        checked={check}
        onChange={() => {
          setCheck((prevCheck) => !prevCheck)
          toggle(check)
        }}
        color={colorPrimary}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      {canShowOnText && (
        <Typography
          sx={{
            display: {
              lg: 'block',
              ...textVisibilityPerViewPort,
            },
          }}
          textTransform={textTransform}
          color={check ? colorPrimary : 'inherit'}
        >
          {onText}
        </Typography>
      )}
    </Box>
  )
}

export default Switch
