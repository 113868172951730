const pages = {
  forbidden: {
    title: 'Access to this page is restricted',
    subtitle:
      'Please check with the site admin if you believe this is a mistake.',
  },
  notfound: {
    title: 'Page Not Found',
    subtitle:
      'Please check with the site admin if you believe this is a mistake.',
  },
  offline: {
    title: 'No Internet Connection',
    subtitle: 'There seems to be an issue with your network connection, please check'
  },
  footer: [
    `©${new Date().getFullYear()} AliveCor, Inc. All Rights Reserved. US Patent
        Nos 8,301,232, 8,509,882 and Patents Pending.`,
    'AliveCor and Kardia are trademarks of AliveCor, Inc. in the United States and other countries.',
  ],
}

export default pages
