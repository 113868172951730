import Button from '@mui/material/Button'
import { styled } from '@mui/material'

const LoadingButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'loading',
})(({ theme, loading }) => ({
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '&:before': {
    content: "''",
    position: 'absolute',
    width: '24px',
    height: '24px',
    border: `2px solid ${theme.palette.primary.main}`,
    opacity: '.8',
    borderBottomColor: 'transparent',
    borderRadius: '50%',
    display: loading ? 'inline-block' : 'none',
    boxSizing: 'border-box',
    animation: loading ? 'spin 1s linear infinite' : 'none',
  },
}))

export default LoadingButton
