import React from 'react'

import Typography from '@mui/material/Typography'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'

import {
  CONTENT,
  PAGE_TITLE,
  VALIDATION_RULES,
} from '../../constants/constants'
import { API_URL, METHODS } from '../../api/constants'
import { request } from '../../api/axios'

import {
  useAxiosPrivate,
  useDocumentTitle,
  useFetchFunction,
} from '../../hooks'

import { mask, validate } from '../../utils'

import { GridRowModes } from '@mui/x-data-grid-premium'
import Layout from '../../layouts/common/Layout'
import {
  CustomEditInputCell,
  GridActionCellItem,
} from '../../components/common/kc-table/components'
import GlobalConfigList from '../../components/GlobalConfigList/GlobalConfigList'

function renderEditCell(params) {
  return <CustomEditInputCell {...params} />
}

const getColumns = ({ onSave, onEdit, onCancel, onDelete, getMode }) => {
  const columns = [
    {
      field: 'created_at',
      hide: true,
    },
    {
      field: 'key',
      headerName: 'Key *',
      minWidth: 200,
      flex: 1,
      editable: true,
      renderEditCell: renderEditCell,
      preProcessEditCellProps: (params) => {
        const hasError = validate(params.props.value, [
          VALIDATION_RULES.REQUIRED,
          VALIDATION_RULES.MUST_START_WITH_LETTER,
          VALIDATION_RULES.ONLY_LETTERS_NUMBERS_DASHES_UNDERSCORE,
        ])
        return { ...params.props, error: hasError !== true ? hasError : false }
      },
    },
    {
      field: 'value',
      headerName: 'Value *',
      minWidth: 200,
      flex: 1,
      editable: true,
      cellClassName: (params) => {
        return params?.row?.encrypted ? 'data-grid-cell-encrypted' : null
      },
      valueGetter: (params) => {
        const isEncrypted = params.row?.encrypted
        return isEncrypted ? mask(params?.value) : params?.value
      },
      renderEditCell: renderEditCell,
      preProcessEditCellProps: (params) => {
        const hasError = validate(params.props.value, [
          VALIDATION_RULES.REQUIRED,
        ])
        return { ...params.props, error: hasError !== true ? hasError : false }
      },
    },
    {
      field: 'encrypted',
      headerName: 'Encrypted',
      minWidth: 80,
      flex: 0.5,
      editable: true,
      type: 'boolean',
    },

    {
      field: 'comment',
      headerName: 'Comment',
      minWidth: 200,
      editable: true,
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      minWidth: 120,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isEncrypted = row?.encrypted
        const mode = getMode(id)
        const isInEditMode = mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionCellItem
              icon={<SaveIcon fontSize="2.5rem" />}
              label="Save"
              onClick={onSave(id)}
              disabled={isEncrypted}
            />,
            <GridActionCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={onCancel(id)}
              color="inherit"
            />,
          ]
        }

        return [
          <GridActionCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={onEdit(id)}
            color="inherit"
            title={
              isEncrypted
                ? 'Encrypted configs are not editable. Please, delete and add a new one instead.'
                : ''
            }
            disabled={isEncrypted}
          />,
          <GridActionCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={onDelete(id)}
            color="inherit"
          />,
        ]
      },
    },
  ]
  return columns
}
const preparePayload = (row) => {
  const { id, isNew, ...rest } = row
  return isNew ? rest : { ...rest, id }
}

const GlobalConfig = () => {
  useDocumentTitle(PAGE_TITLE.GLOBAL_CONFIG)
  const axiosPrivate = useAxiosPrivate()
  const [loading, error, globalConfigData, fetchGlobalConfig] =
    useFetchFunction()

  const fetchGlobalConfigData = (
    { pageNo = 0, pageSize = 10 },
    axiosInstance = axiosPrivate
  ) => {
    fetchGlobalConfig({
      axiosInstance,
      path: `${API_URL.GLOBAL_CONFIG}?pageNo=${pageNo}&pageSize=${pageSize}`,
    })
  }

  const handleUpdate = (newRow) => {
    const { id, ...payload } = newRow
    const path = id ? `${API_URL.GLOBAL_CONFIG}/${id}` : API_URL.GLOBAL_CONFIG
    const method = id ? METHODS.PUT : METHODS.POST
    const data = id ? { ...payload, id } : [payload]
    try {
      return request({
        axiosInstance: axiosPrivate,
        path,
        method,
        data,
      })
    } catch (error) {
      throw error
    }
  }

  const handleSave = async (newRow) => {
    const payload = preparePayload(newRow)
    const { id } = payload
    if (newRow.key?.trim() === '' || newRow.value?.trim() === '') {
      throw new Error(CONTENT.NO_EMPTY_KEY_VALUE)
    } else {
      try {
        const response = await handleUpdate(payload)
        return {
          status: response.status,
          message: id
            ? CONTENT.global_configs.success.update
            : CONTENT.global_configs.success.create,
        }
      } catch (error) {
        throw new Error(error?.response?.data?.errorMsg)
      }
    }
  }

  const handleDelete = async (id) => {
    try {
      const response = await request({
        axiosInstance: axiosPrivate,
        path: `${API_URL.GLOBAL_CONFIG}/${id}`,
        method: METHODS.DELETE,
        data: id,
      })
      return {
        status: response.status,
        message: CONTENT.global_configs.success.delete,
      }
    } catch (error) {
      throw new Error(CONTENT.UNKNOWN_ERROR)
    }
  }
  if (error && error?.status !== 421) {
    return (
      <Layout.CenterWrapper>
        <Typography>{error?.errorMsg}</Typography>
      </Layout.CenterWrapper>
    )
  }
  return (
    <GlobalConfigList
      data={globalConfigData}
      totalRowsCount={globalConfigData?.totalCount}
      loading={loading}
      refetch={fetchGlobalConfigData}
      getColumns={getColumns}
      onSave={handleSave}
      onDelete={handleDelete}
      maxHeight="calc(100% - 1rem)"
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'created_at',
              sort: 'asc',
            },
          ],
        },
      }}
    >
    </GlobalConfigList>
  )
}

export default GlobalConfig
