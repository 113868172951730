import { configureStore } from '@reduxjs/toolkit'
import { authReducer } from '../auth/slices/auth.slice'
import { pageReducer } from '../pages/slices/page.slice'

const store = configureStore({
  reducer: {
    /**
     * Add Reducers here
     */
    auth: authReducer, //Don't remove if you are using GoogleAuth component
    page: pageReducer,
  },
  // disabling redux dev tools on production
  devTools: process.env.NODE_ENV === 'production' ? false : true,
})

export default store
