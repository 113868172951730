import { PATH_PAGE_TITLE_MAP } from '../constants/constants'
import ROUTES from './routes'

// routeConfig will be genreated based on required routes
// can be changed according to the requirment

const protectedRouteConfig = (routeAuthroizationChecker) =>
  // Protected / Private routes config
  Object.keys(ROUTES.PROTECTED).map((key) => ({
    path: ROUTES.PROTECTED[key],
    element: routeAuthroizationChecker(ROUTES.PROTECTED[key]),
    handle: {
      // you can put whatever you want on a route handle
      // here we use "crumb" and return some elements,
      // this is what we'll render in the breadcrumbs
      // for this route
      crumb: () => ({
        title: PATH_PAGE_TITLE_MAP[ROUTES.PROTECTED[key]],
        path: ROUTES.PUBLIC[key],
      }),
    },
  }))

const publicRouteConfig = (routeAuthroizationChecker) =>
  // public routes config
  Object.keys(ROUTES.PUBLIC).map((key) => ({
    path: ROUTES.PUBLIC[key],
    element: routeAuthroizationChecker(ROUTES.PUBLIC[key]),
    handle: {
      // you can put whatever you want on a route handle
      // here we use "crumb" and return some elements,
      // this is what we'll render in the breadcrumbs
      // for this route
      crumb: () => ({
        title: PATH_PAGE_TITLE_MAP[ROUTES.PUBLIC[key]],
        path: ROUTES.PUBLIC[key],
      }),
    },
  }))

const ROUTE_CONFIG = {
  PROTECTED: protectedRouteConfig,
  PUBLIC: publicRouteConfig,
}

export default ROUTE_CONFIG
