import React, { useState } from 'react'
import { request } from '../../api/axios'

import { API_URL, METHODS, STATUS } from '../../api/constants'
import { CONTENT, PAGE_TITLE } from '../../constants/constants'

import FacilityConfigForm from '../../components/FacilityConfigForm'
import FacilityDetailsForm from '../../components/FacilityDetailsForm'
import Snackbar from '../../components/common/Snackbar'

import { useAxiosPrivate, useDocumentTitle } from '../../hooks'
import { useRef } from 'react'
import { deleteConfigRequest } from '../UpdateFacility/UpdateFacility'

const readOnlyFields = ['facility_name', 'practice_timezone', 'connected_application']
const triggerOnMountFields = [ 'facility_code', 'xapi_key']

const CreateFacilityPage = () => {
  const [facilityCreateStatus, setFacilityCreateStatus] = useState(STATUS.IDLE) // idle || loading || completed
  const [configCreateStatus, setConfigCreateStatus] = useState(STATUS.IDLE) // idle || loading || completed
  const [facilityId, setFacilityId] = useState(null)
  const [showSnackbar, setShowSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  })

  const createdConfigs = useRef({})
  useDocumentTitle(PAGE_TITLE.FACILITIES_ADD)
  const axiosPrivate = useAxiosPrivate()

  const createFacility = async (payload) => {
    try {
      setFacilityCreateStatus(STATUS.LOADING)
      const response = await request({
        axiosInstance: axiosPrivate,
        path: API_URL.FACILITIES,
        method: METHODS.POST,
        data: {...payload, active: true},
      })
      if (response.status === 200) {
        setFacilityId(response.data?.facility?.id)
        setShowSnackbar({
          open: true,
          message: CONTENT.facility_details.success.create,
          severity: 'success',
        })
        setFacilityCreateStatus(STATUS.COMPLETED)
      }
    } catch (error) {
      console.log(error)
      setShowSnackbar({
        open: true,
        message:
          CONTENT[error?.response?.data?.errorMsg] ||
          CONTENT.UNKNOWN_ERROR,
        severity: 'error',
      })
      setFacilityCreateStatus(STATUS.FAILED)
    }
  }

  const createConfig = async (payload) => {
    if (!facilityId) return
    try {
      setConfigCreateStatus(STATUS.LOADING)
      const response = await request({
        axiosInstance: axiosPrivate,
        path: `${API_URL.FACILITY_CONFIG}/${facilityId}`,
        method: METHODS.PUT,
        data: payload,
      })
      if (response.status === 200) {
        createdConfigs.current = response?.data
        setShowSnackbar({
          open: true,
          message: CONTENT.facility_configs.success.create,
          severity: 'success',
        })
        setConfigCreateStatus(STATUS.COMPLETED)
      }
    } catch (error) {
      console.log(error?.response?.data)
      setConfigCreateStatus(STATUS.FAILED)
      setShowSnackbar({
        open: true,
        message:
          error?.response?.data?.errorMsg || CONTENT.UNKNOWN_ERROR,
        severity: 'error',
      })
    }
  }

  const handleDelete = async (id) => {
    try {
      const response = await deleteConfigRequest(id, axiosPrivate)
      if (response.status === 200) {
        setShowSnackbar({
          open: true,
          message: CONTENT.facility_configs.success.delete,
          severity: 'success',
        })
      }
    } catch (error) {
      setShowSnackbar({
        open: true,
        message: CONTENT.UNKNOWN_ERROR,
        severity: 'error',
      })
    }
  }

  return (
    <>
      <FacilityDetailsForm
        onSubmit={createFacility}
        readOnlyFields={readOnlyFields}
        triggerOnMountFields={triggerOnMountFields}
        loading={facilityCreateStatus === STATUS.LOADING}
      />
      {facilityCreateStatus === STATUS.COMPLETED ? (
        <FacilityConfigForm
          prefillData={createdConfigs.current}
          facilityId={facilityId}
          onSubmit={createConfig}
          loading={configCreateStatus === STATUS.LOADING}
          status={configCreateStatus}
          onDelete={handleDelete}
        />
      ) : null}
      <Snackbar
        open={showSnackbar.open}
        children={showSnackbar.message}
        severity={showSnackbar.severity}
        onClose={() => setShowSnackbar((prev) => ({ ...prev, open: false }))}
      />
    </>
  )
}

export default CreateFacilityPage
